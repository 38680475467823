<template>
  <section
    class="flex-auto track overflow-hidden"
    :class="{ pause: pause }"
    @mouseenter="stop"
    @mouseleave="start"
    @touchstart="stop"
    @touchend="start"
  >
    <div
      class="h-full track-inner transition-opacity z-30"
      :class="{ 'opacity-100': modes.info, 'opacity-0': !modes.info }"
    >
      <lazy-image :lazySrc="info" />
      <lazy-image :lazySrc="info" />
    </div>
    <div
      class="h-full track-inner transition-opacity z-20"
      :class="{ 'opacity-100': modes.captions, 'opacity-0': !modes.captions }"
    >
      <lazy-image :lazySrc="captions" />
      <lazy-image :lazySrc="captions" />
    </div>
    <div
      class="h-full track-inner transition-opacity z-10"
      :class="{ 'opacity-100': modes.images, 'opacity-0': !modes.images }"
    >
      <lazy-image :lazySrc="images" />
      <lazy-image :lazySrc="images" />
    </div>
    <div
      class="h-full track-inner transition-opacity"
      :class="{
        'opacity-100': modes.illustrations,
        'opacity-0': !modes.illustrations,
      }"
    >
      <lazy-image :lazySrc="illustrations" />
      <lazy-image :lazySrc="illustrations" />
    </div>
  </section>
</template>

<script>
import LazyImage from "@/components/LazyImage.vue";
export default {
  name: "Track",
  props: {
    modes: Object,
    info: String,
    captions: String,
    images: String,
    illustrations: String,
  },
  components: {
    LazyImage,
  },
  data() {
    return {
      pause: false,
    };
  },
  methods: {
    start() {
      console.log("start");
      this.pause = false;
    },
    stop() {
      console.log("stop");
      this.pause = true;
    },
  },
};
</script>
