<template>
  <main class="flex flex-col h-full">
    <Track
      :modes="modes"
      :info="require('@/assets/img/top/top-text.png')"
      :captions="require('@/assets/img/top/top-1.png')"
      :images="require('@/assets/img/top/top-2.png')"
      :illustrations="require('@/assets/img/top/top-3.png')"
    />
    <div
      class="bg-black text-white text-sm md:text-base uppercase w-full flex justify-around"
    >
      <div
        @click="modes.info = !modes.info"
        class="px-4 cursor-pointer select-none"
        :class="{ 'bg-red': modes.info }"
      >
        info
      </div>
      <div
        @click="modes.captions = !modes.captions"
        class="px-4 cursor-pointer select-none"
        :class="{ 'bg-red': modes.captions }"
      >
        captions
      </div>
      <div
        @click="modes.images = !modes.images"
        class="px-4 cursor-pointer select-none"
        :class="{ 'bg-red': modes.images }"
      >
        images
      </div>
      <div
        @click="modes.illustrations = !modes.illustrations"
        class="px-4 cursor-pointer select-none"
        :class="{ 'bg-red': modes.illustrations }"
      >
        illustrations
      </div>
    </div>
    <Track
      class="reverse"
      :modes="modes"
      :info="require('@/assets/img/bottom/bottom-text.png')"
      :captions="require('@/assets/img/bottom/bottom-1.png')"
      :images="require('@/assets/img/bottom/bottom-2.png')"
      :illustrations="require('@/assets/img/bottom/bottom-3.png')"
    />
  </main>
</template>

<script>
import Track from "@/components/Track.vue";

export default {
  name: "Home",
  components: {
    Track,
  },
  data() {
    return {
      modes: {
        info: false,
        captions: false,
        images: true,
        illustrations: false,
      },
    };
  },
};
</script>
