<template>
  <img v-show="!loading" :data-src="lazySrc" class="lozad h-full max-w-none" />
</template>

<script>
import lozad from "lozad";

export default {
  name: "AppImage",
  props: {
    lazySrc: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: true,
    };
  },
  mounted() {
    const setLoadingState = () => {
      this.loading = false;
    };
    this.$el.addEventListener("load", setLoadingState);
    this.$once("hook:destroyed", () => {
      this.$el.removeEventListener("load", setLoadingState);
    });
    // const observer = lozad(this.$el, {
    //   rootMargin: "10px 0px", // syntax similar to that of CSS Margin
    //   threshold: 0.1, // ratio of element convergence
    //   enableAutoReload: true, // it will reload the new image when
    // });

    // observer.observe();
    const observer = lozad();
    observer.observe();

    const coolImage = this.$el;
    // ... trigger the load of a image before it appears on the viewport
    observer.triggerLoad(coolImage);
  },
};
</script>
